<template>
  <div class="container">
    <div class="row polarities-datatable justify-content-center mt-4">
      <InfoBox title="More about Contact Control" class="mb-3"
        >Contact Control allows an admin to keep pairs of students from being on
        an active hallpass at the same time.</InfoBox
      >
      <ActionPanel
        @tab-clicked="setActiveTab"
        class="mb-3"
        show-search
        show-export
        :tabs="tabs"
        @on-search="searchByQuery"
        @on-export="handlePolarities(state.pagination.activePage, true)"
      >
        <template #left-content>
          <div>
            <CustomSelect
              v-if="gradeYears"
              v-model="state.filterParams.grade_years"
              class="width-200-px ms-2"
              :options="gradeYears"
              placeholder="Select year"
              :searchable="false"
              :close-on-select="true"
              @update:model-value="setGardYears"
            ></CustomSelect>
          </div>
        </template>
      </ActionPanel>
      <div class="d-flex justify-content-between align-items-end my-3">
        <CustomCheck
          v-model="state.filterParams.student_sis_id"
          name="Student number"
          label="Student Number"
        ></CustomCheck>
        <PerPage
          v-if="polarities"
          :pagination="state.pagination"
          :showing-entries="true"
          @on-page-change="setPerPage"
        />
      </div>

      <DataTable
        v-if="polarities"
        class="datatable bg-white"
        :items="polarities"
        :fields="activeDataTableFields"
        :sorter="{ external: true }"
        column-filter
        :no-items-view="{
          noResults: 'No items available',
          noItems: 'No items available'
        }"
        @update:sorter-value="sortByColumn"
      >
        <template #search_column>
          <div class="sort-icon-v3">
            <i class="ri-search-line" @click="toggleSearchByColumn"></i>
          </div>
        </template>

        <template #first_student_sis_id="{ item }">
          <td>
            <div>
              {{
                item.first_user && item.first_user.student_sis_id
                  ? item.first_user.student_sis_id
                  : ""
              }}
            </div>
          </td>
        </template>
        <template #first_user="{ item }">
          <td>
            <p class="m-0">
              {{
                item.first_user
                  ? item.first_user.first_name + " " + item.first_user.last_name
                  : "-"
              }}
            </p>
          </td>
        </template>
        <template #first_user-filter>
          <input
            v-show="state.filter"
            v-model="state.columnSearchKeys.first_user.value"
            placeholder="Search by name"
            class="form-control form-control-sm my-2"
            @input="searchByQuery($event.target.value, 'column')"
          />
        </template>

        <template #second_student_sis_id="{ item }">
          <td>
            <div>
              {{
                item.second_user && item.second_user.student_sis_id
                  ? item.second_user.student_sis_id
                  : ""
              }}
            </div>
          </td>
        </template>
        <template #second_user="{ item }">
          <td>
            <p class="m-0">
              {{
                item.second_user
                  ? item.second_user.first_name +
                    " " +
                    item.second_user.last_name
                  : "-"
              }}
            </p>
          </td>
        </template>
        <template #second_user-filter>
          <input
            v-show="state.filter"
            v-model="state.columnSearchKeys.second_user.value"
            placeholder="Search by name"
            class="form-control form-control-sm my-2"
            @input="searchByQuery($event.target.value, 'column')"
          />
        </template>

        <template #times_triggered="{ item }">
          <td>
            {{ item.times_triggered }}
          </td>
        </template>
        <template #times_triggered-filter>
          <input
            v-show="state.filter"
            v-model="state.columnSearchKeys.times_triggered.value"
            placeholder="Search by times triggered"
            class="form-control form-control-sm my-2"
            @input="searchByQuery($event.target.value, 'column')"
          />
        </template>

        <template #message="{ item }">
          <td class="comments-column">
            <div class="v3-comments-list comments-list">
              <div class="comment">
                {{ item.message ? item.message : "" }}
              </div>
            </div>
          </td>
        </template>
        <template #message-filter>
          <input
            v-show="state.filter"
            v-model="state.columnSearchKeys.message.value"
            placeholder="Search by note"
            class="form-control form-control-sm my-2"
            @input="searchByQuery($event.target.value, 'column')"
          />
        </template>

        <template #created_at="{ item }">
          <td>
            {{
              item.created_at
                ? $helpers.transformDate(item.created_at, "MM/DD/YYYY")
                : "-"
            }}
          </td>
        </template>
        <template #created_at-filter>
          <input
            v-show="state.filter"
            v-model="state.columnSearchKeys.created_at.value"
            placeholder="Search by date"
            class="form-control form-control-sm my-2"
            @input="searchByQuery($event.target.value, 'column')"
          />
        </template>

        <template #status="{ item }">
          <td>
            <StatusPill
              :status="item.status ? 'STATUS_ACTIVE' : 'STATUS_INACTIVE'"
              :status-icon="item.status ? 'ri-check-line' : 'ri-close-line'"
              class="cursor-pointer"
              @click="updatePolarityStatus(item.id)"
            >
            </StatusPill>
          </td>
        </template>
        <template #status-filter>
          <input
            v-show="state.filter"
            v-model="state.columnSearchKeys.status.value"
            placeholder="Search by status"
            class="form-control form-control-sm my-2"
            @input="searchByQuery($event.target.value, 'column')"
          />
        </template>

        <template #actions="{ item }">
          <td>
            <div class="d-flex">
              <Actions
                :actions="polaritiesAction"
                @click-action="handleTableAction($event, item)"
              >
              </Actions>
            </div>
          </td>
        </template>

        <template #user="{ item }">
          <td class="normal-height">
            <p class="m-0">
              {{
                item.user
                  ? item.user.first_name + " " + item.user.last_name
                  : "-"
              }}
            </p>
          </td>
        </template>
        <template #user-filter>
          <input
            v-show="state.filter"
            v-model="state.columnSearchKeys.user.value"
            placeholder="Search by name"
            class="form-control form-control-sm my-2"
            @input="searchByQuery($event.target.value, 'column')"
          />
        </template>

        <template #prevented_student_sis_id="{ item }">
          <td>
            <div>
              {{
                item.user && item.user.student_sis_id
                  ? item.user.student_sis_id
                  : ""
              }}
            </div>
          </td>
        </template>

        <template #destination="{ item }">
          <td>
            <p class="m-0">
              {{ item.destination ? item.destination.name : "-" }}
            </p>
          </td>
        </template>
        <template #destination-filter>
          <input
            v-show="state.filter"
            v-model="state.columnSearchKeys.destination.value"
            placeholder="Search by destination"
            class="form-control form-control-sm my-2"
            @input="searchByQuery($event.target.value, 'column')"
          />
        </template>

        <template #out_user="{ item }">
          <td>
            <p class="m-0">
              {{
                item.out_user
                  ? item.out_user.first_name + " " + item.out_user.last_name
                  : "-"
              }}
            </p>
          </td>
        </template>
        <template #out_user-filter>
          <input
            v-show="state.filter"
            v-model="state.columnSearchKeys.out_user.value"
            placeholder="Search by name"
            class="form-control form-control-sm my-2"
            @input="searchByQuery($event.target.value, 'column')"
          />
        </template>

        <template #out_student_sis_id="{ item }">
          <td>
            <div>
              {{
                item.out_user && item.out_user.student_sis_id
                  ? item.out_user.student_sis_id
                  : ""
              }}
            </div>
          </td>
        </template>

        <template #prevented_from-filter>
          <input
            v-show="state.filter"
            v-model="state.columnSearchKeys.prevented_from.value"
            placeholder="Search by location"
            class="form-control form-control-sm my-2"
            @input="searchByQuery($event.target.value, 'column')"
          />
        </template>

        <template #report_created_at="{ item }">
          <td>
            <p class="m-0">
              {{
                item.prevented_at
                  ? $helpers.transformDate(item.prevented_at, "MM/DD/YYYY")
                  : ""
              }}
            </p>
          </td>
        </template>
        <template #report_created_at-filter>
          <input
            v-show="state.filter"
            v-model="state.columnSearchKeys.report_created_at.value"
            placeholder="Search by date"
            class="form-control form-control-sm my-2"
            @input="searchByQuery($event.target.value, 'column')"
          />
        </template>

        <template #prevented_at="{ item }">
          <td>
            <p class="m-0">
              {{
                item.prevented_at
                  ? $helpers.transformDate(item.prevented_at, "h:mm A")
                  : ""
              }}
            </p>
          </td>
        </template>
        <template #prevented_at-filter>
          <input
            v-show="state.filter"
            v-model="state.columnSearchKeys.prevented_at.value"
            placeholder="Search by time"
            class="form-control form-control-sm my-2"
            @input="searchByQuery($event.target.value, 'column')"
          />
        </template>
      </DataTable>
    </div>
    <Pagination
      v-if="
        state.pagination.pages &&
        state.pagination.pages > 1 &&
        !state.isLazyLoadingMode
      "
      class="cs-pagination mt-4"
      :dots="false"
      :double-arrows="true"
      :active-page="state.pagination.activePage"
      :pages="state.pagination.pages"
      align="center"
      size="lg"
      @update:active-page="setActivePage"
    >
    </Pagination>
  </div>
</template>
<script>
import { reactive, computed, onMounted, watch, inject } from "vue"
import { useStore } from "vuex"
import moment from "moment-timezone"
import helpers from "@/helpers/index"
import InfoBox from "@/v3components/shared/Alerts/InfoBox"
import ActionPanel from "@/v3components/shared/DataTable/ActionPanel"
import PerPage from "@/v3components/shared/DataTable/Perpage"
import CustomSelect from "@/v3components/shared/Form/CustomSelect"
import CustomCheck from "@/v3components/shared/Form/CustomCheck"
import DataTable from "@/v3components/shared/DataTable/DataTable"
import StatusPill from "@/v3components/shared/DataTable/StatusPill.vue"
import Actions from "@/v3components/shared/DataTable/Actions"
import Pagination from "@/v3components/shared/DataTable/Pagination"
import download from "@/helpers/downloadCSV"

export default {
  name: "ContactControlTable",
  components: {
    InfoBox,
    ActionPanel,
    PerPage,
    CustomSelect,
    CustomCheck,
    DataTable,
    StatusPill,
    Actions,
    Pagination
  },
  emits: [],
  setup() {
    const store = useStore()
    const actionDialog = inject("actionDialog")
    const modal = inject("modal")

    const state = reactive({
      isLazyLoadingMode: false,
      activeTab: null,
      pagination: {
        activePage: 1,
        total: 0,
        to: 0,
        pages: 0,
        per_page: { label: "25", value: 25 }
      },
      searchTimeOut: null,
      filter: false,
      filterParams: {
        sort_query: "",
        search_query: "",
        grade_years: "",
        dates: {
          end: moment().format("MMM DD YYYY"),
          start: moment().format("MMM DD YYYY")
        },
        student_sis_id: false
      },
      columnSearchKeys: {
        globalQuery: "",
        first_user: {
          value: "",
          column: ["first_user.name"],
          sortBy: "first_user.name"
        },
        second_user: {
          value: "",
          column: ["second_user.name"],
          sortBy: "second_user.name"
        },
        times_triggered: {
          value: "",
          column: ["times_triggered"],
          sortBy: "times_triggered"
        },
        message: { value: "", column: ["message"], sortBy: "message" },
        created_at: {
          value: "",
          column: ["created_at.raw"],
          sortBy: "created_at"
        },
        status: { value: "", column: ["status_string"], sortBy: "status" },

        user: { value: "", column: ["user.name"], sortBy: "user.name" },
        destination: {
          value: "",
          column: ["destination.name"],
          sortBy: "destination.name"
        },
        out_user: {
          value: "",
          column: ["out_user.name"],
          sortBy: "out_user.name"
        },
        prevented_from: {
          value: "",
          column: ["prevented_from"],
          sortBy: "prevented_from"
        },
        report_created_at: {
          value: "",
          column: ["prevented_at.raw"],
          sortBy: "prevented_at"
        },
        prevented_at: {
          value: "",
          column: ["prevented_time.raw"],
          sortBy: "prevented_time"
        }
      }
    })

    const tabs = [
      {
        label: "Summary",
        value: "summary_reports"
      },
      {
        label: "Detailed",
        value: "detailed_report"
      }
    ]

    const dataTableFields = [
      {
        key: "first_student_sis_id",
        filter: false,
        sorter: false,
        label: "Student A number",
        _style: "min-width:150px"
      },
      { key: "first_user", _style: "min-width:110px", label: "Student A" },
      {
        key: "second_student_sis_id",
        filter: false,
        sorter: false,
        label: "Student B number",
        _style: "min-width:150px"
      },
      { key: "second_user", _style: "min-width:110px", label: "Student B" },
      {
        key: "times_triggered",
        _style: "min-width:150px",
        label: "# of times triggered"
      },
      { key: "message", _style: "min-width:150px", label: "Admin Only Note" },
      { key: "created_at", _style: "min-width:150px", label: "Date Created" },
      { key: "status", _style: "min-width:150px", label: "Status" },
      {
        key: "actions",
        _style: "width:50px",
        label: "Actions",
        filter: false
      },
      {
        key: "prevented_student_sis_id",
        filter: false,
        sorter: false,
        label: "Student A number",
        _style: "min-width:150px",
        AdvancedReport: true
      },
      { key: "user", label: "Student Prevented", AdvancedReport: true },

      { key: "destination", label: "Destination", AdvancedReport: true },
      {
        key: "out_student_sis_id",
        filter: false,
        sorter: false,
        label: "Student B number",
        _style: "min-width:150px",
        AdvancedReport: true
      },
      {
        key: "out_user",
        label: "Student on active Pass",
        AdvancedReport: true
      },
      { key: "prevented_from", label: "Attempt from", AdvancedReport: true },
      { key: "report_created_at", label: "Date", AdvancedReport: true },
      { key: "prevented_at", label: "Prevented Time", AdvancedReport: true }
    ]

    const polaritiesAction = [
      {
        label: "Delete",
        icon: "ri-delete-bin-line",
        class: "important",
        action: "delete"
      }
    ]

    const polaritiesCollection = computed(
      () => store.getters["polarities/polarities"]
    )

    const polarityAdvancedReports = computed(
      () => store.getters["polarities/polarityAdvancedReports"]
    )

    const isAdvancedReportMode = computed(() => {
      return state.activeTab && state.activeTab.value
        ? state.activeTab.value == "detailed_report"
          ? true
          : false
        : false
    })

    const gradeYears = computed(() => {
      const gradeYears = []
      const currentYear = new Date().getFullYear()
      const today = moment().format("YYYY-MM-DD HH:mm:ss")
      const newSchoolYear = moment(today).isAfter(
        moment(`${currentYear}-06-30 23:59:59`)
      )
      if (moment(today).year() == currentYear && newSchoolYear) {
        for (let i = 0; i <= currentYear - 2021; i++) {
          gradeYears.push({
            value: [2021 + i, 2022 + i],
            label: `${2021 + i}-${22 + i}`
          })
        }
      } else {
        for (let i = 0; i < currentYear - 2021; i++) {
          gradeYears.push({
            value: [2021 + i, 2022 + i],
            label: `${2021 + i}-${22 + i}`
          })
        }
      }
      return gradeYears
    })

    const minDate = computed(() => {
      if (state.filterParams.grade_years.value) {
        return new Date(state.filterParams.grade_years.value[0], 6, 1)
      }
      return new Date()
    })

    const maxDate = computed(() => {
      if (state.filterParams.grade_years.value) {
        return new Date(state.filterParams.grade_years.value[1], 5, 30)
      }
      return new Date()
    })

    const datesParam = computed(() => {
      return state.filterParams.dates
        ? helpers.currTzDate(state.filterParams.dates.start) +
            "," +
            helpers.currTzDate(state.filterParams.dates.end)
        : null
    })

    const polarities = computed(() => {
      return isAdvancedReportMode.value
        ? polarityAdvancedReports.value
          ? polarityAdvancedReports.value.map((item) => {
              return { ...item, report_created_at: item.created_at }
            })
          : []
        : polaritiesCollection.value
    })

    const activeDataTableFields = computed(() => {
      return dataTableFields
        .filter((el) =>
          isAdvancedReportMode.value ? el.AdvancedReport : !el.AdvancedReport
        )
        .filter((field) =>
          !state.filterParams.student_sis_id
            ? !field.key.includes("student_sis_id")
            : field
        )
    })

    watch(
      () => modal.options.action,
      (isAction) => {
        if (isAction == "createContactControl") {
          handlePolarities()
        }
      }
    )

    onMounted(() => {
      initLazyLoading()
      state.activeTab = tabs[0]
    })

    const setActiveTab = (tab) => {
      state.activeTab = tab
      resetSearchQuery()
      resetParams()
      handlePolarities()
      helpers.scrollToTop()
    }

    const setGardYears = () => {
      state.filterParams.dates = {
        start: minDate,
        end: maxDate
      }
      handlePolarities()
    }

    const toggleSearchByColumn = () => {
      state.filter = !state.filter
    }

    const handlePolarities = (page, isExport, isDelete, isLazyLoadingMode) => {
      if (isDelete && page) {
        state.pagination.total = state.pagination.total - 1
        state.pagination.pages = Math.ceil(
          state.pagination.total / state.pagination.per_page.value
        )
        state.pagination.activePage =
          page < state.pagination.pages ? page : state.pagination.pages
      } else {
        state.pagination.activePage = page ? page : 1
      }
      const params = {
        page: state.pagination.activePage,
        per_page: state.pagination.per_page.value,
        dates: datesParam.value
      }
      if (state.activeTab) {
        params[state.activeTab.value] = true
      }
      if (state.filterParams.search_query) {
        params.search_query = state.filterParams.search_query
      }
      if (state.filterParams.sort_query) {
        params.sort = state.filterParams.sort_query
      }
      if (isExport) {
        exportCSVPolarities(params)
      } else {
        if (isAdvancedReportMode.value) {
          getDetailedReports(params, isLazyLoadingMode)
        } else {
          getSummaryReport(params, isLazyLoadingMode)
        }
      }
    }

    const getSummaryReport = (params, isLazyLoadingMode) => {
      store.dispatch("polarities/getPolarities", params).then((response) => {
        const data = response.data
        if (data) {
          if (isLazyLoadingMode) {
            store.commit("polarities/PUSH_POLARITIES", data.data)
          } else {
            store.commit("polarities/SET_POLARITIES", data.data)
          }
          state.pagination.total = data.meta.total
          state.pagination.from = data.meta.from
          state.pagination.to = data.meta.to
          state.pagination.pages = Math.ceil(
            data.meta.total / data.meta.per_page
          )
          state.pagination.activePage = Number(state.pagination.activePage)
          state.pagination = JSON.parse(JSON.stringify(state.pagination))
        }
      })
    }

    const getDetailedReports = (params, isLazyLoadingMode) => {
      store
        .dispatch("polarities/getPolarityAdvancedReports", params)
        .then((response) => {
          const data = response.data
          if (data) {
            if (isLazyLoadingMode) {
              store.commit(
                "polarities/PUSH_POLARITY_ADVANCED_REPORTS",
                data.data
              )
            } else {
              store.commit(
                "polarities/SET_POLARITY_ADVANCED_REPORTS",
                data.data
              )
            }
            state.pagination.total = data.meta.total
            state.pagination.from = data.meta.from
            state.pagination.to = data.meta.to
            state.pagination.pages = Math.ceil(
              data.meta.total / data.meta.per_page
            )
            state.pagination.activePage = Number(state.pagination.activePage)
            state.pagination = JSON.parse(JSON.stringify(state.pagination))
          }
        })
    }

    const exportCSVPolarities = (params) => {
      params.per_page = [25, 50, 100].includes(state.pagination.per_page.value)
        ? state.pagination.per_page.value
        : "all"
      store
        .dispatch("polarities/getCSVpolaritiesExport", {
          ...params,
          student_sis_id: state.filterParams.student_sis_id
        })
        .then((response) => {
          if (response.data) {
            download.CSVExport(response.data, "polarities")
          }
        })
    }

    const searchByQuery = (value, type) => {
      clearInterval(state.searchTimeOut)
      state.searchTimeOut = setTimeout(() => {
        handleQuerySearch(value, type)
      }, 500)
    }

    const handleQuerySearch = (value, type) => {
      const query = value.toString()
      if (
        query.replace(/\s/g, "").length > 0 ||
        query.replace(/\s/g, "").length === 0
      ) {
        state.filterParams.search_query = ""
        if (type && type === "column") {
          state.columnSearchKeys.globalQuery = ""
          for (const key in state.columnSearchKeys) {
            if (Object.hasOwnProperty.call(state.columnSearchKeys, key)) {
              const columnData = state.columnSearchKeys[key]
              if (columnData.value) {
                columnData.column.forEach((col) => {
                  state.filterParams.search_query =
                    state.filterParams.search_query +
                    `${col}:${
                      col === "times_triggered"
                        ? columnData.value
                        : '"' + columnData.value + '"'
                    }, `
                })
              }
            }
          }
          state.filterParams.search_query =
            state.filterParams.search_query.slice(0, -2)
        } else {
          resetSearchQuery()
          state.columnSearchKeys.globalQuery = query
          state.filterParams.search_query = query ? '"' + query + '"' : query
        }
        handlePolarities()
      }
    }

    const sortByColumn = (column) => {
      const columnName = state.columnSearchKeys[column.column]
        ? state.columnSearchKeys[column.column].sortBy
        : column.column
      state.filterParams.sort_query = `${columnName}:${
        column.asc ? "asc" : "desc"
      }`
      handlePolarities()
    }

    const updatePolarityStatus = (polarityID) => {
      if (polarityID) {
        store
          .dispatch("polarities/updatePolarityStatus", polarityID)
          .then((response) => {
            const data = response.data.data
            if (data) {
              store.commit("polarities/UPDATE_POLARITY_STATUS", data)
            }
          })
      }
    }

    const handleTableAction = (event, item) => {
      if (event.action == "delete") {
        actionDialog.open(deletePolarities, {
          args: item,
          props: { danger: true, title: "Delete", question: "Are you sure?" }
        })
      }
    }

    const deletePolarities = (polarity) => {
      store.dispatch("polarities/deletePolarity", polarity.id).then(() => {
        store.commit("polarities/DELETE_POLARITY", polarity.id)
        handlePolarities(state.pagination.activePage, false, true)
      })
    }

    const setPerPage = (option) => {
      if (option) {
        state.isLazyLoadingMode = option.label === "All entries"
        state.pagination.per_page = option
        handlePolarities()
      }
    }

    const initLazyLoading = () => {
      const body = document.getElementsByClassName("main-scroll-container")[0]
      if (body) {
        body.onscroll = () => {
          if (
            state.isLazyLoadingMode &&
            polarities.value &&
            polarities.value.length &&
            state.pagination.total > polarities.value.length
          ) {
            if (body.offsetHeight + body.scrollTop + 1 >= body.scrollHeight) {
              state.pagination.activePage = state.pagination.activePage + 1
              handlePolarities(state.pagination.activePage, false, false, true)
            }
          }
        }
      }
    }

    const setActivePage = (page) => {
      state.pagination.activePage = page
      handlePolarities(state.pagination.activePage)
      helpers.scrollToTop()
    }

    const resetSearchQuery = () => {
      state.filterParams.search_query = ""
      state.columnSearchKeys = {
        globalQuery: "",
        first_user: {
          value: "",
          column: ["first_user.name"],
          sortBy: "first_user.name"
        },
        second_user: {
          value: "",
          column: ["second_user.name"],
          sortBy: "second_user.name"
        },
        times_triggered: {
          value: "",
          column: ["times_triggered"],
          sortBy: "times_triggered"
        },
        message: { value: "", column: ["message"], sortBy: "message" },
        created_at: {
          value: "",
          column: ["created_at.raw"],
          sortBy: "created_at"
        },
        status: { value: "", column: ["status_string"], sortBy: "status" },

        user: {
          value: "",
          column: ["user.name"],
          sortBy: "user.name"
        },
        destination: {
          value: "",
          column: ["destination.name"],
          sortBy: "destination.name"
        },
        out_user: {
          value: "",
          column: ["out_user.name"],
          sortBy: "out_user.name"
        },
        prevented_from: {
          value: "",
          column: ["prevented_from"],
          sortBy: "prevented_from"
        },
        report_created_at: {
          value: "",
          column: ["prevented_at.raw"],
          sortBy: "prevented_at"
        },
        prevented_at: {
          value: "",
          column: ["prevented_time.raw"],
          sortBy: "prevented_time"
        }
      }
    }

    const resetParams = () => {
      state.filterParams = {
        search_query: "",
        sort_query: "",
        student_sis_id: state.filterParams.student_sis_id
      }
    }

    return {
      state,
      tabs,
      dataTableFields,
      polaritiesAction,
      gradeYears,
      polarities,
      activeDataTableFields,
      setActiveTab,
      setGardYears,
      toggleSearchByColumn,
      handlePolarities,
      searchByQuery,
      sortByColumn,
      updatePolarityStatus,
      handleTableAction,
      setPerPage,
      setActivePage
    }
  }
}
</script>
